import React from 'react'
import styles from './Header.module.css'; // Import CSS modules
import Header__Image from './Images/entrepreneur_managing_business.png';
import RegisterForm from '../../../../components/RegisterForm';
import { Link } from 'react-router-dom';
const Header = () => {
    return (
        <div className={styles.Header__Section}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 text-center text-lg-start'>
                        <div class="trustpilot-widget" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="65947f5dca2d64ea55912e85" data-style-height="150px" data-style-width="100%">
                            <a href="https://www.trustpilot.com/review/payyit.com" target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                        <h1 className={styles.Main__Title}>Manage your money like a boss.</h1>
                        <div className={styles.Description}>
                            <p>Create beautiful invoices, accept online payments, and make accounting easy—all in one place—with Payyit's suite of money management tools.</p>
                        </div>
                        <RegisterForm />
                        <div className='Text__Gray mt-3'>
                            <p>By signing up, you are indicating that you have read and agree to the <Link to={'/terms'}>Terms of Use</Link> and <Link to={'/privacy'}>Privacy Policy</Link>.</p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <figure className={styles.Header__Image}>
                            <img src={Header__Image} alt='' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header
